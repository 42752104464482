<template>
  <transition name="fade">
    <slot />
  </transition>
</template>

<script>
export default {
  name: "FadeTransition"
};
</script>
<style scoped lang="scss">
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s ease-in-out;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
