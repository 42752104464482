<template>
  <button
    class="mb-1 text-center position-relative d-inline-block border-0"
    :class="$style.glowyButton"
    v-on="{ ...$listeners }"
  >
    <slot />
  </button>
</template>

<script>
export default {
  name: "GlowyButton"
};
</script>

<style module lang="scss">
@import "../../styles/imports";
button {
  outline: none;
  &:focus {
    outline: none;
    box-shadow: 0 0 0 3px $fokusButton;
  }
}
.glowyButton {
  cursor: pointer;
  text-decoration: none;
  color: $white;
  text-transform: uppercase;
  font-family: "Barlow", sans-serif;

  font-weight: $font-weight-bold;
  font-size: $font-size-xs;
  padding: 23px 30px;
  border-radius: 50px;
  background-color: $main-color;
  box-shadow: none;
  transition: all 0.2s ease-in-out;
  user-select: none;

  &:hover {
    background: $hover-btn;
    box-shadow: $box-gallery-hover;
  }
}
</style>
