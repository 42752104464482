<template>
  <div class="text-center mb-3">
    <GlowyButton class="mt-2 mb-2" @click="isContactModal = true">
      {{ openButtonText }}
    </GlowyButton>
    <FadeTransition>
      <div
        v-if="isContactModal"
        style="position: fixed; z-index: 98; top: 0; left: 0;"
        class="modal-background w-100 h-100 d-flex justify-content-center align-items-center"
        @click.self="closeModal"
      >
        <div
          class="modal px-1 position-relative v-grid justify-content-center pt-md-2 px-lg-2 px-md-2"
        >
          <transition name="fade">
            <div
              v-if="isNotificationShown"
              style="background-color: white; z-index: 1; width: 100%; height: 100%; flex-direction: column; top: -20px"
              class="position-absolute text-center d-flex justify-content-center modal-thanks"
              v-html="$t('success')"
            ></div>
          </transition>

          <div class="text-center">
            <h1 class="font-weight-bold text-primary pt-4 pt-md-1">
              {{ heading }}
            </h1>
            <div
              class="modal-close position-absolute"
              @click.self="closeModal"
            />
          </div>

          <slot name="pre-form"></slot>

          <form action="" method="get" class="px-lg-1">
            <div class="v-grid">
              <div class="grid grid-gap grid-template w-100 pb-1">
                <TextInput
                  v-model="form.company"
                  :placeholder="$t('company')"
                  validation="required"
                />
                <TextInput
                  v-model="form.name"
                  :placeholder="$t('name')"
                  validation="required"
                />
                <TextInput
                  v-model="form.phone"
                  :placeholder="$t('phone')"
                  validation="required"
                />
                <TextInput
                  v-model="form.email"
                  :placeholder="$t('email')"
                  validation="required|email"
                />
              </div>
              <div class="position-relative d-block w-100 mb-1">
                <textarea
                  v-model="form.message"
                  class=" d-block w-100 mb-1"
                  v-validate.disabled="`required`"
                  :placeholder="$t('message')"
                  :class="`${errors.first(`Ihre Nachricht`) ? 'error' : ''}`"
                  data-vv-name="Ihre Nachricht"
                  data-vv-as="`Ihre Nachricht`"
                />
                <transition name="fade">
                  <span
                    class="textareaError"
                    v-if="errors.first(`Ihre Nachricht`)"
                    >{{ errors.first(`Ihre Nachricht`) }}</span
                  >
                </transition>
              </div>
              <input
                id="check"
                v-model="form.agree"
                type="checkbox"
                v-validate.disabled="`required`"
                data-vv-name="Datenschutzerklärung"
                data-vv-as="`Datenschutzerklärung`"
              />
              <label
                :class="
                  `${errors.first(`Datenschutzerklärung`) ? 'error' : ''}`
                "
                ref="agree"
                for="check"
                class="v-grid font-size-sm text-left"
                @click.prevent="form.agree = !form.agree"
              >
                {{ $t("privacyLabel") }}
                <a
                  class="modal-link"
                  @click.stop="redirectLink"
                  style="padding: 0 5px; text-decoration: underline;"
                  :href="dataProtectionLink"
                  target="_blank"
                  >{{ $t("privacyLink") }}</a
                >
              </label>
            </div>
          </form>
          <div class="mt-2 mb-2">
            <button
              style="outline:none"
              class="open-contact-btn d-inline-block text-center"
              @click.self="sendForm"
            >
              {{ $t("send") }}
            </button>
          </div>
        </div>
      </div>
    </FadeTransition>
  </div>
</template>

<script>
import TextInput from "@/components/basic/TextInput";
import FadeTransition from "@/components/transitions/FadeTransition";
import GlowyButton from "@/components/basic/GlowyButton";
import { sendForm } from "@/utils/webhookHelper";
import { layoutState } from "@/components/layouts/layoutStyles";

export default {
  name: "ContactUs",
  components: { GlowyButton, FadeTransition, TextInput },
  props: {
    heading: {
      type: String,
      required: true
    },
    openButtonText: {
      type: String,
      required: true
    },
    beforeSendCallback: {
      type: Function,
      default: answers => answers
    }
  },

  data() {
    return {
      isContactModal: false,
      isNotificationShown: false,
      form: this.defaultFormValues(),
      body: document.body
    };
  },
  provide() {
    return {
      $validator: this.$validator
    };
  },
  mounted() {
    this.body.classList.add("modal-open");
  },
  destroyed() {
    this.body.classList.remove("modal-open");
  },
  computed: {
    dataProtectionLink() {
      return this.$router.resolve({ name: "datenschutz" }).href;
    }
  },
  methods: {
    closeModal() {
      this.isContactModal = false;
    },
    redirectLink() {
      window.open(this.dataProtectionLink, "_blank");
    },
    async sendForm() {
      if (!(await this.$validator.validate())) return;
      const confirm = Object.keys(this.form).some(element => {
        return !this.form[element];
      });

      if (!confirm) {
        this.isNotificationShown = true;
        setTimeout(async () => {
          const answers = this.beforeSendCallback(this.form);
          await sendForm(answers, this.$route.name);
          this.closeModal();
          this.form = this.defaultFormValues();
        }, 2000);
        setTimeout(() => {
          this.isNotificationShown = false;
        }, 2400);
      }
    },

    defaultFormValues() {
      return {
        name: null,
        company: null,
        phone: null,
        email: null,
        message: null,
        agree: false
      };
    }
  },
  watch: {
    isContactModal(isOpen) {
      layoutState.hideHeader = isOpen;
    }
  },
  i18n: {
    messages: {
      de: {
        success: `<strong>Vielen Dank für Ihre Nachricht.</strong><span>Wir melden uns bei Ihnen!</span>`,
        yourChoices: `Ihre Zusammenstellung`,
        duration: "DAUER",
        months: "{duration} MONATE",
        company: "Firma",
        name: "Ansprechpartner",
        phone: "Telefonnummer",
        email: "E-Mail",
        message: "Ihre Nachricht",
        privacyLink: "Hier lesen",
        privacyLabel:
          "Die Datenschutzerklärung habe ich gelesen und stimme ihr hiermit zu: ",
        send: "Abschicken"
      },
      en: {
        success: `<strong>Thank you for your message.</strong><span>We will get back to you!</span>`,
        yourChoices: "Your summary",
        duration: "DURATION",
        months: "{duration} MONTHS",
        company: "Company",
        name: "Contact person",
        phone: "Phone number",
        email: "E-mail",
        message: "Your message",
        privacyLink: "Read here",
        privacyLabel: "I have read and agree to the privacy policy: ",
        send: "Submit"
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../../styles/imports";
h1 {
  font-size: $font-size-base;
  @include breakpoint(md) {
    font-size: $font-size-md;
  }
}
p {
  font-size: $font-size-sm;
  @include breakpoint(md) {
    font-size: $font-size-base;
  }
}
input,
textarea {
  border-radius: 0;
  outline: none;
  border: 0;
  border-bottom: thin solid $border-color;
  font-family: $font-family-sans-serif;
  font-size: $font-size-sm;
}
textarea {
  transition: all 0.2s ease-in-out;
  resize: none;
}
input[type="checkbox"] {
  display: none;
}
label {
  position: relative;
  margin: 0 0 0 15px;
  user-select: none;
  cursor: pointer;
  &::before {
    content: "";
    position: absolute;
    display: inline-block;
    width: 18px;
    height: 18px;
    border: thin solid $text-color-dark;
    left: -25px;
    top: 3px;
  }
  a {
    color: $text-color-dark;
    &:hover {
      color: $main-color;
    }
  }
}
input[type="checkbox"]:checked + label::before {
  width: 18px;
  height: 18px;
  background-color: $main-color;
  background: url("../../assets/img/modal/check.svg") no-repeat 50%;
  background-size: 100%;
}
label.error {
  color: $error-color;
  &::before {
    border: thin solid $error-color;
  }
  a {
    color: $error-color;
    &:hover {
      color: $error-color;
    }
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s ease-in-out;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
textarea.error {
  border-bottom: thin solid $error-color;
  color: $error-color;
  &::placeholder {
    color: $error-color;
  }
}
.textareaError {
  color: $error-color;
  font-size: 0.8rem;
  position: absolute;
  top: 100%;
  left: 0;
  margin-top: -20px;
}

.open-contact-btn {
  font-size: $font-size-sm;
  outline: none;
}
.modal-thanks {
  height: 110%;
  margin-top: 20px;
  position: fixed;
}
.techs {
  color: $second-color;
  margin-bottom: $font-size-base;
  & > span {
    font-size: $font-size-sm;
    flex-shrink: 0;
    & > i {
      font-family: sans-serif;
      display: inline;
      margin: 0 0.25em;
    }

    &:last-child {
      & > i {
        display: none;
      }
    }
  }
}

.modal {
  box-shadow: $box-shadow-card;
  background-color: $body-bg;
  @include breakpoint(md) {
    max-width: $maxContainerSize / 2;
  }
}
.modal-close {
  cursor: pointer;
  width: 24px;
  height: 24px;
  right: 30px;
  top: 20px;
  background: url("../../assets/img/modal/close-gray.svg") no-repeat;
  background-size: 100%;
  @include breakpoint(md) {
    top: 30px;
  }
}
.modal-spec {
  height: 118px;
  background: $modal-spec-color;
  strong {
    font-size: $font-size-sm;
  }
}

.modal-link {
  display: block;
  @include breakpoint(md) {
    display: contents;
  }
}
.modal-background {
  overflow-y: scroll;
  @include breakpoint(md) {
    background: hsla(0, 0%, 100%, 1);
  }
  @include breakpoint(lg) {
    overflow-y: hidden;
    background: hsla(0, 0%, 100%, 0.6);
  }
}
</style>
