<template>
  <div>
    <div class="container">
      <HeaderBasic
        v-if="title || subtitle"
        :title="title"
        :subtitle="subtitle"
      />
    </div>

    <slot />

    <div class="container" v-if="!hideConsultation">
      <div class="v-grid ">
        <div class="col-2 col-offset">
          <div class="font-base mb-3 text-block">
            <div class="text-center mb-1">
              <slot name="contact-us">
                <ContactUs
                  :heading="$t('contactUs.heading')"
                  :open-button-text="$t('bookConsultation')"
                >
                  <template #pre-form>
                    <div>
                      <p class="mb-md-2 mb-sm-1">
                        {{ $t("contactUs.introText") }}
                      </p>
                    </div>
                  </template>
                </ContactUs>
              </slot>
            </div>

            <div class="mb-4 text-primary text-block">
              <p class="subtitle">{{ $t("subtitle") }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <slot name="post-consultation"></slot>

    <div class="container">
      <div class="v-grid">
        <div class="col-2 col-offset">
          <ChatBot @onFinish="onFinish" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ChatBot from "@/components/chatbot";
import { sendForm } from "@/utils/webhookHelper";
import { layoutState } from "@/components/layouts/layoutStyles";
import HeaderBasic from "@/components/basic/HeaderBasic";
import ContactUs from "@/components/modals/ContactUs";

export default {
  name: "WhitePageLayout",
  components: {
    ContactUs,
    HeaderBasic,
    ChatBot
  },
  props: {
    title: {
      default: "",
      type: String
    },
    subtitle: {
      default: "",
      type: String
    },
    pageIcon: {
      type: String,
      default: ""
    },
    hideConsultation: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isContactModal: false
    };
  },

  methods: {
    async onFinish(answers) {
      try {
        await sendForm(answers, `Projekt Starten`);
      } catch (e) {
        // TODO: Error handler
      }
    },
    toggleOutput(e) {
      e.currentTarget.classList.toggle("outputAnimate");
    },
    openContactModal() {
      this.isContactModal = true;
    },
    scrollBlock(e) {
      if (this.isContactModal) {
        e.preventDefault();
      }
    }
  },
  watch: {
    isContactModal(value) {
      layoutState.hideHeader = value;
    }
  },
  created() {
    layoutState.setPageConfig("light", true);
    layoutState.slogan = this.$t("slogan.digitalInnovation");
    layoutState.showPhone = true;
    layoutState.setLogo("img", {
      src: this.pageIcon,
      style: "width: 55px; height: auto"
    });
  },
  i18n: {
    messages: {
      de: {
        subtitle: `Die Grundlage einer guten Software ist das Verständnis der
          Kundenanforderung. Im Beratungsgespräch nehmen wir uns die Zeit,
          Ihre Anforderungen ausgiebig und sorgfältig zu verstehen. Dabei
          ist die Erstberatung selbstverständlich kostenlos und
          unverbindlich.`,
        bookConsultation: "Termin vereinbaren",
        contactUs: {
          heading: `Lassen Sie uns über Ihr Projekt sprechen.`,
          introText: `Wir bereiten uns vor jedem Treffen gut vor. Je besser Sie uns ihr Projekt beschreiben, desto effektiver gestaltet sich unser Kennenlernen. Bis bald!`
        }
      },
      en: {
        subtitle: `Good software is based on a good understanding of customer requirements. We take extra care to understand your needs during our initial consultation, which is, of course, free of charge and without obligation.`,
        bookConsultation: `Book consultation`,
        contactUs: {
          heading: "Let us talk about your project.",
          introText: `We prepare well before each meeting. The better you describe your project to us, the more effective our meeting will be. See you soon!`
        }
      }
    }
  }
};
</script>
<style scoped lang="scss">
@import "../../styles/imports";

.subtitle {
  font-size: $font-size-subtitle;
  line-height: $line-height-medium;
  @include breakpoint(md) {
    font-size: $font-size-sm;
  }
}
</style>
