<template>
  <div class="header-title text-center px-md-2 mb-3">
    <h2 class="m-0" v-html="title"></h2>
    <p class="" v-html="subtitle"></p>
  </div>
</template>

<script>
export default {
  name: "HeaderBasic",
  props: {
    title: {
      type: String,
      required: true
    },
    subtitle: {
      type: String,
      required: true
    }
  }
};
</script>

<style scoped lang="scss">
@import "../../styles/imports";
.header-title {
  margin: 60px 0 0 0;
  padding: 60px 0 0 0;
  h2,
  p {
    font-size: $font-size-medium;
  }

  @include breakpoint(md) {
    padding: 50px 0 0 0;
    margin: 100px 0 100px 0;
    h2,
    p {
      font-size: $font-size-md;
      line-height: $line-height-small;
    }
  }

  @include breakpoint(lg) {
    padding: 0;
    h2,
    p {
      font-size: $font-size-lg;
      line-height: $line-height-base;
    }
    h2 {
      padding: 40px 0 0 0;
    }
  }
}
</style>
