<template>
  <div class="story-paragraph">
    <div class="font-base mb-3 text-block">
      <h3 class="d-inline-block mb-1" v-if="$slots.title">
        <slot name="title"></slot>
      </h3>
      <div>
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "StoryText",
  props: {
    title: {
      type: String,
      default: ""
    }
  }
};
</script>

<style scoped lang="scss">
@import "../../styles/imports";
.story-paragraph {
  h3 {
    font-size: $font-size-medium;
    color: $primary;
    @include breakpoint(md) {
      font-size: $font-size-md;
    }
  }

  p {
    font-size: $font-size-medium;
    line-height: $line-height-base;
    @include breakpoint(md) {
      font-size: $font-size-base;
    }
  }

  .text-block {
    margin: 0 0 60px 0;
    @include breakpoint(md) {
      margin: 0 0 100px 0;
    }
  }
}
</style>
