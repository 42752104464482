<template>
  <div class="flex-col position-relative" :class="`d-flex ${className}`">
    <input
      v-model="textField"
      v-validate.disabled="validation"
      class="w-100 pb-1"
      :class="`${errors.first(placeholder) ? 'error' : ''} ${className}`"
      :placeholder="placeholder"
      :data-vv-name="placeholder"
      :data-vv-as="placeholder"
    />
    <transition name="fade">
      <span class="position-absolute" v-if="errors.first(placeholder)">{{
        errors.first(placeholder)
      }}</span>
    </transition>
  </div>
</template>

<script>
export default {
  inject: ["$validator"],
  props: ["placeholder", "className", "value", "props", "validation"],
  data() {
    return {
      textField: this.value
    };
  },
  watch: {
    textField(value) {
      this.$emit("input", value);
    }
  }
};
</script>

<style scoped lang="scss">
@import "../../styles/imports";

.fade-enter-active,
.fade-leave-active {
  transition: all 0.2s ease-in-out;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
  transform: translateY(-10px);
}
input {
  border-radius: 0;
  outline: none;
  border: 0;
  border-bottom: thin solid $border-color;
  font-family: $font-family-sans-serif;
  font-size: $font-size-sm;
  transition: all 0.3s;
}
input.error {
  border-bottom: thin solid $error-color;
  color: $error-color;
  &::placeholder {
    color: $error-color;
  }
}
span {
  color: $error-color;
  font-size: $font-size-subtitle;
  padding: 5px 0 0 0;
  top: 100%;
}
</style>
